<template>
  <div class="kefu">
    <div class="bgsw">
      <div class="bgtit">客服页预览</div>
      <div class="kfw">
        <div class="kfo">
          <div class="kfmidbg"></div>
          <div class="kfomid">
            <div class="toptips" :style="{'color':data.topcolor}">{{data.toptxt}}</div>
            <img class="kfimg" :src="data.qrcode.nv" />
          </div>
        </div>
        <div class="bottips">
          <div class="btxt" :style="{'color':data.bottomcolor}">{{data.bottomtxt}}</div>
          <div class="fkbtn" @tap="openurl('feedback')">我要反馈</div>
        </div>
      </div>
      <div class="upbtnw" v-if="false">
        <upload class="bgubtn" btnTxt="点击上传客服背景" action="upload_img.html" imgName="bgimg" @upSuccess="upSuccess"></upload>
        <div class="sizetips">建议尺寸：宽度 690px 高度：1012px ~ 1096px (客服页背景色：#212121)</div>
      </div>
    </div>
    <div class="bgsw">
      <div class="bgtit">上方文字</div>
      <div class="attw">
        <Input type="textarea" class="bknr" :autosize="{minRows: 3,maxRows: 3}" placeholder="内容" maxlength="500" v-model="data.toptxt" />
      </div>
      <ColorPicker class="selcolor" v-model="data.topcolor" @on-change="color($event,'topcolor')" />
    </div>
    <div class="bgsw">
      <div class="bgtit">二维码设置</div>
      <div class="bklis">
        <div class="qrcodeimg"><img :src="data.qrcode.nan" /></div>
        <upload class="qrubtn" btnTxt="上传男客服二维码" action="upload_img.html" imgName="nan" @upSuccess="upSuccess"></upload>
      </div>
      <div class="bklis">
        <div class="qrcodeimg"><img :src="data.qrcode.nv" /></div>
        <upload class="qrubtn" btnTxt="上传女客服二维码" action="upload_img.html" imgName="nv" @upSuccess="upSuccess"></upload>
      </div>
    </div>
    <div class="bgsw">
      <div class="bgtit">下方文字</div>
      <div class="attw">
        <Input type="textarea" class="bknr"  :autosize="{minRows: 3,maxRows: 3}" placeholder="内容" maxlength="500" v-model="data.bottomtxt" />
      </div>
      <ColorPicker class="selcolor" v-model="data.bottomcolor" @on-change="color($event,'bottomcolor')" />
    </div>
    <div class="bgsw">
      <div class="bgtit"></div>
      <Button type="info" style="width: 120px" @click="save_edit">保存</Button>
    </div>
    <Spin size="large" fix v-if="page_loading"></Spin>
  </div>
</template>
<script>
import upload from "@/components/public/upload";
export default {
  components:{upload},
  name: 'Kefu',
  data(){
    return {
      data:{
        bgimg:'',
        qrcode:{},
        toptxt:'',
        topcolor:'#999999',
        bottomtxt:'',
        bottomcolor:'#999999'
      },
      page_loading:false,
    }
  },
  created() {
    this.init();
  },
  methods:{
    init:function (){
      var _this=this;
      this.page_loading=true;
      this.requestApi('/adm/mkefu.html').then(function (res){
        _this.page_loading=false;
        if(res.status==1) _this.data=res.data;
      })
    },
    /**
     * 上传成功
     */
    upSuccess:function(e){
      if(e.name=='bgimg') this.data.bgimg = e.response.url;
      else if (e.name=='nan') this.data.qrcode.nan = e.response.url;
      else if (e.name=='nv') this.data.qrcode.nv = e.response.url;
    },
    /**
     * 保存选项
     */
    save_edit:function (){
      var _this=this;
      this.page_loading=true
      this.requestApi('/adm/save_mkefu.html',this.data).then(function (res){
        _this.page_loading=false
        if(res.status==1) _this.tipSuccess(res.msg);
        else _this.alertError(res.msg);
      })
    },
    /**
     * 设置字体颜色
     */
    color:function (color,key){
      if(color == '') this.data[key]='#999999';
    }
  }
}
</script>
<style lang="less">
@import "~@/assets/css/sysset/kefu";
</style>